<template>
    <div class="popup_sub_container add_contact_adj">
        <h2>ADD REFERENCES</h2>
        <i class="closeAddContact" @click="closeModal">X</i>
        <div class="form_section1">
            <table>
                <tr v-for="qty in parseInt(prepaid.requested_qty)" :key="qty">
                    <td>
                        <div class="label_and_element_wrapper">
                            <label>
                                Reference Number {{ qty }}
                            </label>
                            <input type="text" v-model="referenceNumber[qty - 1]">
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <section class="centered">
            <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
            <a class="link_bt bt_save" @click="addReference">Save</a>
        </section>
    </div>
</template>
<script>
export default {
    props:['prepaid'],
    data() {
        return {
            referenceNumber:[],
        }
    },
    methods: {
        closeModal() {
            this.modalStatus = false;
            this.$emit("closeModal", this.modalStatus);
        },
        addReference() {
            this.$emit("addReference", this.referenceNumber);
            this.closeModal()
        }
    }
}
</script>
<style scoped>
.form_section1 {
  overflow-y: scroll;
  max-height: 500px;
}
</style>